@import '../../utils/styles/variables.scss';

.description-item {
    display: flex;
    flex-direction: column;

    &__number {
        color: $main-green;
        font-size: 60px;
        font-family: $font-medium;
        line-height: 60px;
        letter-spacing: -1.2px;
        text-transform: uppercase;

        margin-bottom: 20px;
    }

    &__title {
        color: $black-text;
        font-size: $fs-subtitle;
        font-family: $font-medium;
        line-height: $lh-subtitle;
        letter-spacing: $ls-subtitle;

        margin-bottom: 10px;
    }

    &__content {
        color: $black-text;
        font-size: $fs-text;
        font-family: $font-regular;
        line-height: $lh-text;
        letter-spacing: $ls-text;
    }
}

@media screen and (max-width: 500px) {
    .description-item {
        &__number {
            font-size: 40px;
            letter-spacing: -0.8px;
            line-height: 50px;

            margin-bottom: 2px;
        }

        &__title {
            font-size: $m-fs-subtitle;
            line-height: $m-lh-subtitle;
            letter-spacing: $m-ls-subtitle;

            margin-bottom: 5px;
        }

        &__content {
            font-size: $m-fs-text;
            line-height: $m-lh-text;
            letter-spacing: $m-ls-text;
        }
    }
}
