body {
    margin: 0;
    background-color: #f8f7f3;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Stratos LC Web Medium';
    src: local('Stratos LC Web Medium'),
        url('./utils/fonts/StratosLCWeb/StratosLCWeb-Medium.ttf')
            format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Stratos LC Web Regular';
    src: local('Stratos LC Web Regular'),
        url('./utils/fonts/StratosLCWeb/StratosLCWeb-Regular.ttf')
            format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Stratos LC Web Light';
    src: local('Stratos LC Web Light'),
        url('./utils/fonts/StratosLCWeb/StratosLCWeb-Light.ttf')
            format('truetype');
}
