/* ** fonts ** */
$font-medium: 'Stratos LC Web Medium';
$font-regular: 'Stratos LC Web Regular';
$font-light: 'Stratos LC Web Light';

/* ** colors ** */
$white: #fff;
$main-white: #dfdfdf;
$main-green: #c0e709;
$black-text: #262626;
$black-light: #2d2c2a;

/* ** sizes ** */
$fs-title: 40px;
$fs-subtitle: 32px;
$fs-text: 20px;

/* ** line-heights ** */
$lh-title: 40px;
$lh-subtitle: 38px;
$lh-text: 24px;

/* ** letter-spacings ** */
$ls-title: -0.8px;
$ls-subtitle: -0.64px;
$ls-text: -0.4px;

//for mobile
/* ** sizes ** */
$m-fs-title: 24px;
$m-fs-subtitle: 18px;
$m-fs-text: 14px;

/* ** line-heights ** */
$m-lh-title: 28px;
$m-lh-subtitle: 22px;
$m-lh-text: 18px;

/* ** letter-spacings ** */
$m-ls-title: -0.48px;
$m-ls-subtitle: -0.36px;
$m-ls-text: -0.28px;
