@import '../../utils/styles/variables.scss';

.about-work {
    margin: 0 auto 112px auto;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin-bottom: 30px;
    }

    &__frame {
        width: 908px;
        height: 497px;
        border-radius: 25px;

        display: flex;

        margin: 0 auto;
    }
}

@media screen and (max-width: 1400px) {
    .about-work {
        &__frame {
            width: 850px;
            height: 450px;
        }
    }
}

@media screen and (max-width: 900px) {
    .about-work {
        &__frame {
            width: 100%;
            height: 380px;
        }
    }
}

@media screen and (max-width: 500px) {
    .about-work {
        margin-bottom: 60px;

        &__title {
            font-size: 30px;
            line-height: 30px;

            margin: 0 0 20px 0;
        }

        &__frame {
            height: 250px;
        }
    }
}
