@import '../../utils/styles/variables.scss';

.block-advantage {
    border-radius: 40px;
    background-color: #fff;
    background-image: url(../../utils/images/background-advantage.png);
    background-position: top left;
    background-repeat: no-repeat;

    padding: 50px;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin-bottom: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 35px;

        &-image {
            width: 80%;
            height: auto;

            margin-top: -95px;
            margin-left: 100px;
            margin-right: -100px;
        }

        &-text {
            color: #1a1a1a;
            font-size: 50px;
            font-family: $font-medium;
            line-height: 55px;
            letter-spacing: -1px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }

    &__row-card {
        display: flex;
        gap: 20px;

        margin-bottom: 20px;
    }

    &__card {
        display: flex;
        flex: 1;
        align-items: flex-start;
        gap: 30px;

        padding: 30px;

        &-text {
            color: #1a1a1a;
            font-family: $font-regular;
            font-size: $fs-text;
            line-height: $lh-text;
            letter-spacing: $ls-text;
            max-width: 500px;

            &-green {
                color: #c0e709;
                display: inline-block;
            }
        }

        img {
            width: 72px;
            height: 72px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .block-advantage {
        &__header {
            gap: 0;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 30px;

            &-image {
                max-width: 100%;
                height: auto;

                margin-top: -80px;
            }

            &-text {
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .block-advantage {
        &__row-card {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 800px) {
    .block-advantage {
        background: none;
        padding: 0;
        margin-bottom: 65px;

        &__header {
            border-radius: 40px;
            background-color: #fff;

            padding: 30px 20px;
            margin-bottom: 40px;

            &-image {
                width: 100%;

                margin-left: 0;
                margin-right: 0;
            }
        }

        &__card {
            height: 100%;
            align-items: center;

            border-radius: 15px;
            background: #fff;

            padding: 20px;

            &-text {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.32px;
            }
        }

        .swiper-wrapper {
            align-items: stretch;
        }

        .swiper-slide {
            height: auto;
        }

        .swiper-custom-pagination {
            text-align: center;
            margin-top: 12px;
        }

        .swiper-pagination-bullet {
            background-color: #aaa4a4;
            width: 12px;
            height: 12px;

            &-active {
                background-color: #c0e709;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .block-advantage {
        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;

            margin-bottom: 20px;
        }

        &__header {
            align-items: flex-start;

            &-text {
                font-size: 24px;
                line-height: 28px;
                letter-spacing: -0.48px;
                margin-bottom: 5px;
            }

            &-image {
                margin-top: -50px;
            }
        }

        &__card {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            padding: 20px;

            &-text {
                max-width: 255px;
                font-size: $m-fs-text;
                line-height: $m-lh-text;
                letter-spacing: $m-ls-text;
            }
        }
    }
}
