@import '../../utils/styles/variables.scss';

.questions {
    margin: 0 auto 130px auto;
    position: relative;

    background-image: url('../../utils/images/green-gradient.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left -60% top 0;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin: 0 0 80px auto;
    }

    &__group-content {
        display: flex;
        align-items: flex-start;
        gap: 40px;
    }

    &__image {
        margin-top: -50px;
        margin-right: 20px;

        width: 35%;
        height: auto;
    }
}

@media screen and (max-width: 1400px) {
    .questions {
        background-image: none;

        &__image {
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    .questions {
        margin: 0 auto 42px auto;

        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;

            margin: 0 0 22px auto;
        }

        &__group-content {
            gap: 0;
        }
    }
}
