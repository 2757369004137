@import '../../utils/styles/variables.scss';

.field{
    &__input {
        font-family: $font-regular;
        font-size: 16px;
        color: #000;
        font-size: 20px;
        line-height: normal;
    
        position: relative;
        width: 100%;
        min-width: 210px;
        min-height: 60px;
        background: transparent;
        border-radius: 10px;
        border: 1px solid #8D8D8D;
    
        padding: 18px 15px 17px 15px;
    
        &::placeholder{
            color: #8D8D8D;
        }
    
        &:focus{
            border: 3px solid #7a7878;
            outline: none;
        }
    
        &:disabled{
            opacity: none;
            pointer-events: none;
        }

        &--error{
            border: 2px solid #dd8c8c;
            outline: none;
        }
    }

    &__caption {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        color: #dd8c8c;
        text-align: center;
        font-size: 14px;
        font-family: $font-regular;
    
        line-height: 16px;
        letter-spacing: -0.28px;

        padding-left: 15px;
        padding-top: 2px;
    }
}

