@import '../../utils/styles/variables.scss';

.header {
    position: relative;

    &__video-media {
        width: 100%;
        object-fit: cover;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;

        background-color: $black-text;
        opacity: 0.3;
    }

    &__video-wrapper {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $black-text;
    }

    &__content {
        position: relative;

        margin: 0 auto 0 auto;
        padding: 47px 35px 0 35px;

        z-index: 3;
    }

    &__logo {
        height: 50px;
        width: 244px;
    }

    &__title {
        width: 522px;
        height: 250px;

        color: $white;
        font-size: 80px;
        font-family: $font-medium;
        line-height: 102.5%;
        letter-spacing: -3.2px;
        text-transform: uppercase;

        margin-top: 12%;
    }

    &__subtitle {
        width: 750px;

        color: #dfdfdf;
        font-family: $font-regular;
        font-size: 24px;
        font-style: normal;
        line-height: 28px;

        margin-top: 30px;
    }

    &__buttons-group {
        display: flex;
        gap: 10px;

        margin-top: 100px;
        margin-bottom: 8%;
    }
}

@media screen and (max-width: 1500px) {
    .header {
        &__title {
            margin-top: 5%;
        }
    }
}

@media screen and (max-width: 1400px) {
    .header {
        &__video-media {
            object-position: 85% top;
        }

        &__video-wrapper {
            object-position: right;
        }
    }
}

@media screen and (max-width: 800px) {
    .header {
        &__video-wrapper {
            height: 150vh;
        }

        &__title {
            width: initial;
            height: initial;

            font-size: 80px;
            line-height: 80px;
            letter-spacing: -2px;
            margin-top: 85px;
        }

        &__subtitle {
            width: initial;
        }

        &__buttons-group {
            flex-direction: column;

            .button {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .header {
        &__content {
            margin: unset;
            padding: 20px 0 0;
        }

        &__logo {
            width: 100px;
            height: 21px;
        }

        &__title {
            width: 250px;
            height: 150px;

            font-size: 50px;
            line-height: 100%;
            letter-spacing: -2px;

            margin-top: 80%;
        }

        &__subtitle {
            width: 345px;
            font-size: 16px;
            line-height: 22px;
        }

        &__buttons-group {
            flex-direction: column;

            margin-top: 33px;
            margin-bottom: 50px;

            .button .button__text {
                padding-top: 11px;
                padding-bottom: 11px;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .header {
        &__title {
            margin-top: 30%;
        }
    }
}
