@import '../../utils/styles/variables.scss';

.button {
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    min-width: 300px;

    &__text {
        text-align: center;
        font-size: 20px;
        font-family: $font-medium;
        line-height: 28px;
        letter-spacing: -0.4px;
        text-transform: uppercase;

        padding: 14px 0;
    }

    &:disabled {
        opacity: 50%;
        cursor: not-allowed;
    }

    svg {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    &__image {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
    }

    &__image + &__text {
        padding-left: 10px;
    }
}

//кнопка без фона
.button--colorless {
    background: transparent;
    border: 2px solid #dfdfdf;
    cursor: pointer;

    .button__text {
        color: white;
    }

    &:hover {
        border: 2px solid #c0e709;

        svg g path {
            fill: #c0e709 !important;
        }

        svg path {
            fill: #c0e709 !important;
        }

        .button__text {
            color: #c0e709;
        }
    }

    &:active {
        outline: none;
        border: 2px solid #a0c104;

        svg g path {
            fill: #a0c104 !important;
        }

        svg path {
            fill: #a0c104 !important;
        }

        .button__text {
            color: #a0c104;
        }
    }
}

//зеленая кнока
.button--green {
    background: #c0e709;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
        margin-right: 0;
    }

    .button__text {
        color: black;
    }

    &:hover {
        background: #e6ff6f;
    }

    &:active {
        background: #a0c104;
    }
}
