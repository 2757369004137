@import '../../utils/styles/variables.scss';

.products {
    margin: 116px auto 119px auto;

    background-image: url('../../utils/images/green-gradient.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left -15% top 0;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin-bottom: 65px;
    }

    &__group-card {
        display: flex;
        justify-content: space-between;
        gap: 9px;
    }

    &__card {
        display: flex;
        flex-direction: column;

        width: 465px;

        padding: 30px;

        &-image {
            position: relative;
            z-index: 2;
            height: 150px;

            margin: -45px 0 20px;
        }

        &-title-group {
            display: flex;
            gap: 20px;

            margin-bottom: 20px;

            img {
                width: 56px;
                height: 56px;
            }
        }

        &-title {
            color: $black-text;
            font-family: $font-medium;
            font-size: $fs-subtitle;
            line-height: $lh-subtitle;
            letter-spacing: $ls-subtitle;
        }

        &-subtitle {
            color: #707070;
            font-size: 16px;
            font-family: $font-regular;
            line-height: 20px;
            letter-spacing: -0.32px;
        }

        &-text {
            color: $black-text;
            font-family: $font-regular;
            font-size: $fs-text;
            line-height: $lh-text;
            letter-spacing: $ls-text;

            margin-bottom: 20px;
        }

        &-button {
            margin-top: auto;
            min-width: unset;
        }
    }
}

@media screen and (max-width: 1400px) {
    .products {
        &__card {
            width: unset;
            max-width: 450px;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .products {
        background-image: none;

        &__group-card {
            justify-content: space-evenly;
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 800px) {
    .products {
        &__card {
            max-width: initial;
            min-width: initial;
            height: 450px;
            min-height: unset;
            max-height: unset;

            padding: 20px;

            &-title-group {
                margin-bottom: 15px;
            }

            &-text {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .swiper-custom-pagination {
            text-align: center;
            margin-top: 12px;
            height: 30px;
        }

        .swiper-pagination-bullet {
            background-color: #aaa4a4;
            width: 12px;
            height: 12px;

            &-active {
                background-color: #ed742e;
            }

            &-active:nth-child(1) {
                background-color: #ed742e;
            }

            &-active:nth-child(2) {
                background-color: #8042f6;
            }

            &-active:nth-child(3) {
                background-color: #c0e709;
            }

            &-active:nth-child(4) {
                background-color: #2451de;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .products {
        margin-bottom: 70px;
        margin-top: 0;

        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;

            margin-bottom: 31px;
        }

        &__card {
            min-width: unset;
            height: 350px;

            &-image {
                position: relative;
                z-index: 2;
                height: 108px;

                margin: -45px 0 15px;
            }

            &-title-group {
                gap: 10px;

                img {
                    width: 38px;
                    height: 38px;
                }
            }

            &-title {
                font-size: $m-fs-subtitle;
                line-height: $m-lh-subtitle;
                letter-spacing: $m-ls-subtitle;
            }

            &-subtitle {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.24px;
            }

            &-text {
                font-size: $m-fs-text;
                line-height: $m-lh-text;
                letter-spacing: $m-ls-text;
            }

            &-button {
                .button__text {
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                }
            }
        }
    }
}
