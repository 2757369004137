@import '../utils/styles/variables.scss';

.main {
    &__consultation-block {
        position: fixed;

        bottom: 0;
        left: auto;
        right: 30px;

        display: flex;
        gap: 10px;
        align-items: center;

        z-index: 10;

        border-radius: 45px 45px 0px 0px;
        background: $black-light;

        padding: 15px;

        &-image {
            width: 44px;
            height: 44px;
            border-radius: 50%;
        }

        &-button {
            .button__text {
                padding: 12px 0;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
                letter-spacing: -0.32px;
            }

            svg {
                width: 14px;
                height: 14px;

                margin-right: 10px;
            }
        }
    }
}

.__wrapper {
    min-height: 100%;
    overflow: hidden;

    max-width: 1580px;

    margin: 0 auto;
    padding: 0 15px;
    box-sizing: content-box;
}

.main__consultation-block--hidden {
    display: none;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #2e2e2e;

    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    padding: 5px 15px;

    &__logo {
        width: 100px;
        height: 18px;
    }

    &__button {
        border: 1.5px solid #d7ff00;
        width: 118px;
        min-width: unset;

        .button__text {
            padding: 8px 0;
            width: 118px;

            color: #d7ff00;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
            letter-spacing: -0.28px;
        }
    }
}

@media screen and (max-width: 800px) {
    .main__consultation-block {
        right: 15px;
        gap: 5px;

        border-radius: 0px;
        background: none;
        padding: 12px;

        &-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}
