@import '../../utils/styles/variables.scss';

.footer {
    border-radius: 40px 40px 0px 0px;
    background: $black-light;
    background-image: url('../../utils/images/background-footer.svg');
    background-position: bottom;
    background-repeat: no-repeat;

    margin: 0 auto;

    a:hover {
        opacity: 0.6;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        padding: 44px 50px 0 50px;
    }

    &__content-info {
        &-title {
            color: #fff;
            font-size: 80px;
            font-family: $font-regular;
            line-height: 82px;
            letter-spacing: -3.2px;
            text-transform: uppercase;

            margin-bottom: 19px;
        }

        &-text {
            color: $main-white;
            font-family: $font-regular;
            font-size: $fs-text;
            line-height: $lh-text;
            letter-spacing: $ls-text;

            margin-bottom: 20px;
        }

        &-group-button {
            display: flex;
            gap: 10px;

            margin-bottom: 30px;
        }
    }

    &__content-contact {
        display: flex;
        gap: 23px;
        align-items: center;

        margin-right: 40px;

        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }

        &-name {
            color: $main-green;
            font-size: 26px;
            line-height: 30px;
            font-family: $font-medium;
            line-height: normal;

            margin-bottom: 10px;
        }

        &-text {
            color: #fff;
            font-size: 20px;
            font-family: $font-regular;
            line-height: 24px;
            text-decoration: none;
        }

        &-link {
            display: flex;

            margin-top: 10px;

            &-text {
                color: $main-green;
                font-family: $font-regular;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.4px;
                text-decoration-line: underline;
                text-transform: uppercase;
                text-decoration: none;

                border-bottom: 2px solid #c0e709;
                padding-bottom: 5px;
            }

            &::before {
                content: ' ';
                display: block;
                width: 18px;
                height: 18px;

                background-image: url(../../utils/images/greenTelegram.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 18px;

                margin-right: 10px;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-top: 2px solid #6c6a61;
        padding: 15px 50px 21px 50px;

        &-group {
            display: flex;
            align-items: center;
        }

        &-text {
            color: #6c6a61;
            font-size: 20px;
            font-family: $font-regular;
            line-height: 24px;
        }

        &-green-link {
            display: inline-block;
            color: $main-green;
            font-size: 20px;
            font-family: $font-regular;
            line-height: 24px;

            text-decoration: none;
        }

        &-grey-link {
            display: inline-block;
            color: #6c6a61;
            font-size: 20px;
            font-family: $font-regular;
            line-height: 24px;

            text-decoration: none;
            margin-right: 50px;
        }

        &-button {
            min-width: unset;
            width: 162px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        &__content {
            flex-direction: column;
            gap: 0;
        }

        &__content-info {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            &-group-button {
                margin-bottom: 20px;
            }
        }

        &__content-contact {
            margin-bottom: 40px;
        }

        &__bottom {
            &-group {
                flex-direction: column;
                align-items: center;
            }

            &-grey-link {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .footer {
        background-position: bottom;

        margin: 0 auto;

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 20px;
        }

        &__content-info {
            width: 100%;

            &-title {
                font-size: 40px;
                line-height: 40px;
                letter-spacing: -1.6px;

                margin-bottom: 5px;
            }

            &-text {
                font-size: 14px;
                line-height: 18px;
                width: 230px;

                margin-bottom: 20px;
            }

            &-group-button {
                flex-direction: column;

                .button__text {
                    padding: 9px 0;

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 125% */
                    letter-spacing: -0.32px;
                }
            }
        }

        &__content-contact {
            gap: 10px;
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;

            img {
                width: 48px;
                height: 48px;
            }

            &-name {
                font-size: 18px;
                line-height: 14px;
            }

            &-text {
                font-size: 10px;
                line-height: 14px;

                display: flex;
                align-items: center;
                gap: 40px;
            }

            &-link {
                margin-top: 14px;

                &-text {
                    font-size: 14px;
                    font-style: normal;
                    line-height: 18px;
                    margin-top: 5px;
                    margin-bottom: -5px;

                    padding-bottom: 1px;
                }
            }
        }

        &__bottom {
            margin: 0 20px;
            padding: 15px 0 18px 0;

            &-group {
                flex-direction: column;
                align-items: flex-start;

                .button {
                    width: 100px;

                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }

                .button__text {
                    padding: 9px 0;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 133.333% */
                    letter-spacing: -0.24px;
                }

                .button .button__image {
                    margin-left: 10px;
                }
            }

            &-text {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
            }

            &-green-link {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
            }

            &-grey-link {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;

                margin-bottom: 0;
            }
        }
    }
}
