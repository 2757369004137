@import '../../utils/styles/variables.scss';

.description__wrapper {
    background: url('../../utils/images/background-description.svg');
}

.description {
    display: flex;
    gap: 75px;
    align-items: center;

    margin: 0 auto 100px auto;
    padding-top: 80px;

    &__content {
        display: flex;
        flex-direction: column;

        &-title {
            color: $black-light;
            font-size: $fs-title;
            font-family: $font-medium;
            line-height: $lh-title;
            letter-spacing: $ls-title;
            text-transform: uppercase;

            margin-bottom: 40px;
        }

        &-row {
            display: flex;
            gap: 54px;

            margin-bottom: 32px;
        }
    }

    &__image {
        width: 25%;
        height: auto;
    }
}

@media screen and (max-width: 1100px) {
    .description {
        &__image {
            display: none !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .description {
        &__content {
            &-row {
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .description {
        padding-top: 56px;
        margin: 0 auto 27px auto;

        &__content {
            &-title {
                line-height: $m-lh-title;
                font-size: $m-fs-title;
                letter-spacing: $m-ls-title;

                margin-bottom: 13px;
            }

            &-row {
                gap: 10px;
                flex-wrap: wrap;

                margin-bottom: 10px;
            }
        }
    }
}
