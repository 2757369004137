@import '../../utils/styles/variables.scss';

.tariffs {
    margin: 0 auto 92px auto;

    background-image: url('../../utils/images/green-gradient.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right -30% top 0;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin-bottom: 64px;
    }

    &__group-cards {
        display: flex;
        justify-content: space-evenly;
        gap: 9px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 500px;
        width: 388px;
        position: relative;

        background-image: url('../../utils/images/background-card.svg');
        background-position: left;
        background-repeat: no-repeat;

        padding: 30px;
        margin-bottom: 23px;

        &-image {
            position: relative;
            height: 150px;
            z-index: 2;

            margin: -60px 0 30px;
        }

        &-info-image {
            position: absolute;
            width: 50%;
            top: 102px;
            right: -10px;
            z-index: 2;
        }

        &-title {
            color: #000;
            color: $black-text;
            font-family: $font-medium;
            font-size: $fs-subtitle;
            line-height: $lh-subtitle;
            letter-spacing: $ls-subtitle;
            text-transform: uppercase;

            margin-bottom: 26px;
        }

        &-price {
            color: $main-green;
            font-size: 60px;
            font-family: $font-medium;
            line-height: 56px;
            letter-spacing: -1.2px;

            margin-bottom: 15px;
        }

        &-text {
            color: $black-text;
            color: $black-text;
            font-family: $font-regular;
            font-size: $fs-text;
            line-height: $lh-text;
            letter-spacing: $ls-text;
            max-width: 328px;

            margin-bottom: 10px;
        }

        &-button {
            margin-top: auto;
            width: 100%;
            min-width: unset;
        }
    }
}

.tariffs__card-text:last-child {
    margin-bottom: 30px;
}

@media screen and (max-width: 1252px) {
    .tariffs {
        &__card {
            &-price {
                font-size: 50px;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .tariffs {
        background-image: none;

        &__group-cards {
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 500px) {
    .tariffs {
        margin: 0 auto 57px auto;

        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;

            margin-bottom: 30px;
        }

        &__card {
            width: 100%;
            min-height: unset;

            background-image: none;

            &-image {
                height: 120px;
                margin: -50px 40px 0 auto;
            }

            &-info-image {
                position: absolute;
                top: 20px;
                left: 30px;
                z-index: 2;
                width: 30%;
                transform: rotate(-25deg);

                margin-bottom: 15px;
            }

            &-title {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.4px;

                margin-bottom: 15px;
                margin-top: 10px;
            }

            &-price {
                font-size: 60px;
                line-height: 60px;
                letter-spacing: -1.2px;

                margin-bottom: 10px;
            }

            &-text {
                font-size: $m-fs-text;
                line-height: $m-lh-text;
                letter-spacing: $m-ls-text;
            }

            &-button {
                .button__text {
                    padding-top: 9px;
                    padding-bottom: 9px;

                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                }
            }
        }
    }
}
