@import "../../utils/styles/variables.scss";

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  &__button-close {
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    border: none;
    outline: none;
  }

  &.modal--active {
    opacity: 1;
    z-index: 100;
    pointer-events: all;
  }

  &__content {
    position: absolute;
    transform: scale(0.5);
    transition: 0.4s all;

    &.modal__content--active {
      transform: scale(1);
    }
  }
}

@media screen and (max-width: 800px) {
  .modal {
    height: 100%;
    width: 100%;
    &__content {
      width: 100%;
      height: 100%;
    }
  }
}
