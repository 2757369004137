@import '../../utils/styles/variables.scss';

.block__wrapper {
    background: #fff;
}

.block {
    position: relative;
    border-radius: 40px;
    padding: 50px 50px 30px 50px;
    margin: 0 auto;

    background-image: url(../../utils/images/background-block.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-color: #fafafa;

    &__title {
        color: $black-light;
        font-family: $font-medium;
        font-size: $fs-title;
        line-height: $lh-title;
        letter-spacing: $ls-title;
        text-transform: uppercase;

        margin-bottom: 20px;
    }

    &__button {
        margin: 40px auto 0 auto;
    }

    &__row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }

    .card {
        display: flex;
        gap: 20px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &__card-content {
        padding: 30px 0 30px 30px;
    }

    &__card-image {
        height: 160px;
        margin-top: 20px;
    }

    &__card-title {
        color: $black-text;
        font-family: $font-medium;
        font-size: $fs-subtitle;
        line-height: $lh-subtitle;
        letter-spacing: $ls-subtitle;

        margin-bottom: 20px;
    }

    &__card-text {
        color: $black-text;
        font-family: $font-regular;
        font-size: $fs-text;
        line-height: $lh-text;
        letter-spacing: $ls-text;
    }

    &__card--small {
        flex-grow: 0.5;

        .block__card-image {
            margin-right: 55px;
        }

        .block__card-text {
            width: 220px;
        }
    }

    &__card--medium {
        flex-grow: 1;

        .block__card-image {
            margin-right: 90px;
        }

        .block__card-text {
            width: 320px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .block {
        &__card-image {
            padding: 15px;
        }

        &__card--small {
            .block__card-image {
                margin-right: 20px;
            }
        }

        &__card--medium {
            .block__card-image {
                margin-right: 20px;
            }
        }
    }
}

@media screen and (max-width: 1230px) {
    .block {
        &__card-content {
            padding: 20px;
        }

        .card {
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
        }

        &__card--small {
            flex-grow: 0;
        }

        &__card--medium {
            flex-grow: 0;
        }
    }
}

@media screen and (max-width: 800px) {
    .block {
        .card {
            gap: 0;
            width: 100%;
        }
    }
}

@media screen and (max-width: 730px) {
    .block {
        &__row {
            flex-wrap: wrap;
            flex-direction: column;
        }

        &__card--small {
            .block__card-image {
                margin-right: unset;
            }
        }

        &__card--medium {
            .block__card-image {
                margin-right: unset;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .block {
        padding: 30px 20px 20px;

        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;

            margin-bottom: 12px;
        }

        &__card-content {
            position: relative;
        }

        &__card-content {
            width: 100%;
        }

        &__card-title {
            font-size: $m-fs-subtitle;
            line-height: $m-lh-subtitle;
            letter-spacing: $m-ls-subtitle;
            margin-bottom: 5px;
        }

        &__card-text {
            font-size: $m-fs-text;
            line-height: $m-lh-text;
            letter-spacing: $m-ls-text;
        }

        &__card-image {
            padding: 0 30px 0 0;
            width: 50%;
            height: auto;

            margin: 20px auto -65px auto;

            &--small-rotate {
                transform: rotate(34.554deg);
            }
        }

        &__button {
            width: 100%;

            margin-top: 30px;

            .button__text {
                font-size: 16px;
                line-height: 20px;

                letter-spacing: -0.32px;

                padding-top: 11px;
                padding-bottom: 11px;
            }
        }
    }
}
