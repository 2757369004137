@import '../../utils/styles/variables.scss';

.question-item {
    border-top: 2.5px solid #918f85;
    padding: 35px 25px;
    cursor: pointer;

    &:last-child {
        border-bottom: 2.5px solid #918f85;
    }

    &__group-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__group-title::after {
        content: '';
        display: block;
        width: 45px;
        height: 45px;
        background-image: url('../../utils/images/icon-open.svg');
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.5s;

        margin-left: 10px;
    }

    &__title {
        color: $black-text;
        font-family: $font-regular;
        font-size: $fs-subtitle;
        line-height: $lh-subtitle;
        letter-spacing: $ls-subtitle;
    }

    &__text {
        color: $black-light;
        font-family: $font-regular;
        font-size: $fs-text;
        line-height: $lh-text;
        letter-spacing: $ls-text;
        white-space: pre-wrap;

        margin-top: 20px;
    }
}

.ReactCollapse--collapse {
    transition: height 0.5s;
}

.question-item--open {
    .question-item__group-title::after {
        transform: rotate(-45deg);
    }
}

@media screen and (max-width: 500px) {
    .question-item {
        padding: 20px 0;

        &__group-title::after {
            background-size: 30px;
        }

        &__title {
            font-size: $m-fs-title;
            letter-spacing: $m-ls-title;
            line-height: $m-lh-title;
        }

        &__text {
            font-size: $m-fs-subtitle;
            line-height: $m-lh-subtitle;
            letter-spacing: $m-ls-subtitle;

            margin-top: 10px;
        }
    }
}
