.partners {
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;

    overflow: hidden;
    padding: 41px 0 43px 0;

    & img {
        margin-left: 50px;
        height: 40px;
        vertical-align: middle;
    }
}

@media screen and (max-width: 500px) {
    .partners {
        padding: 30px 0 38px 0;

        & img {
            height: 35px;
        }
    }
}
