@import '../../utils/styles/variables.scss';

.request-modal {
    &__content {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        background: #f8f7f3;
        padding: 40px;
        border-radius: 25px;
    }

    &__group-content {
        display: flex;
        flex-direction: column;
    }

    &__group-form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 350px;
    }

    &__title {
        color: $black-light;
        font-size: 60px;
        font-family: $font-medium;
        line-height: 60px;
        letter-spacing: -1.2px;
        text-transform: uppercase;
        max-width: 265px;

        margin-bottom: 20px;
    }

    &__input {
        margin-bottom: 10px;
    }

    &__loading {
        margin: 0 auto;
    }

    &__button {
        width: 100%;

        margin-top: 20px;
        margin-bottom: 10px;

        .button__text {
            font-family: $font-regular;
        }
    }

    &__text {
        width: 310px;
        color: #8d8d8d;
        font-family: $font-regular;
        font-size: 20px;
        line-height: normal;
        letter-spacing: -0.4px;

        margin: 0 auto;
    }

    &__small-text {
        width: 290px;
        color: #8d8d8d;
        text-align: center;
        font-size: 14px;
        font-family: $font-regular;
        line-height: 16px;
        letter-spacing: -0.28px;

        margin: 0 auto;
    }

    &__image {
        margin-right: -40px;
        margin-bottom: -40px;
        margin-top: -40px;
        width: 430px;
        height: 630px;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        opacity: 0.6;
    }
}

@media screen and (max-width: 800px) {
    .request-modal {
        &__content {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;
            border-radius: unset;
            height: 100%;
        }

        &__group-content {
            align-items: center;
        }

        &__image {
            margin-bottom: 0;
            margin-top: 0;
            width: 250px;
            height: 30%;
        }

        &__title {
            font-size: 35px;
            line-height: 35px;
            letter-spacing: -2px;
            max-width: initial;
        }

        &__button .button__text {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }
}
